import apr182024 from "../../../../../../../images/blogCovers/202404/celebration.jpg";
import sep212024 from "../../../../../../../images/blogCovers/202409/crowd.jpg";
import nov192024 from "../../../../../../../images/blogCovers/202410/celebrationKSLandscape.png";
import mar122025 from "../../../../../../../images/blogCovers/202503/cFire1.jpeg";
import mar152025 from "../../../../../../../images/blogCovers/202503/vancouver2.jpg";

import fcDallasLogo from "../../../../../../../images/teams/soccer/mens/mls/fcDallas.png";

export const fcDallasBlogs = [
  {
    link: "/soccer/mens/mls/fc-dallas/fc-dallas-cae-en-casa-ante-vancouver-y-sigue-sin-levantar-cabeza",
    image: mar152025,
    alt: "FC Dallas' dribbling the ball forward.",
    timeOfPost: "Mar 15, 2025",
    title: "Fc Dallas cae en casa ante Vancouver y sigue sin levantar cabeza",
    author: "Felipe de Jesus Alcala",
    keyword: "FC DALLAS",
    teamName: "Fc Dallas",
    headerSubtitle: "vs Vancouver Whitecaps",
    teamLogo: fcDallasLogo,
    id: 5,
  },
  {
    link: "/soccer/mens/mls/fc-dallas/fc-dallas-inicia-la-temporada-con-derrota-en-casa-ante-chicago-fire",
    image: mar122025,
    alt: "FC Dallas' dribbling the ball forward.",
    timeOfPost: "Mar 8, 2025",
    title:
      "Fc Dallas inicia la temporada con derrota en casa ante Chicago Fire",
    author: "Felipe de Jesus Alcala",
    keyword: "FC DALLAS",
    teamName: "Fc Dallas",
    headerSubtitle: "vs Chicago Fire",
    teamLogo: fcDallasLogo,
    id: 4,
  },
  {
    link: "/soccer/mens/mls/fc-dallas/fc-dallas-manda-a-kansas-city-fuera-de-play-offs",
    image: nov192024,
    alt: "FC Dallas players celebrating after scoring a goal against Kansas City.",
    timeOfPost: "Oct 19, 2024",
    title: "Fc Dallas manda a Kansas City fuera de play offs",
    author: "Felipe de Jesus Alcala",
    keyword: "FC DALLAS",
    teamName: "Fc Dallas",
    headerSubtitle: "vs Kansas City",
    teamLogo: fcDallasLogo,
    id: 3,
  },
  {
    link: "/soccer/mens/mls/fc-dallas/fc-dallas-aplasta-a-la-fc-con-dominio-total-y-racha-extendida",
    image: sep212024,
    alt: "A lively crowd enjoying their team's dominating performance.",
    timeOfPost: "Sep 21, 2024",
    title: "Fc Dallas aplasta a LAFC con dominio total y racha extendida",
    author: "Jose Maldonado VI",
    keyword: "FC DALLAS",
    teamName: "Fc Dallas",
    headerSubtitle: "vs LAFC",
    teamLogo: fcDallasLogo,
    id: 2,
  },
  {
    link: "/soccer/mens/mls/fc-dallas/clasico-tejano-fc-dallas-vs-houston-dynamo-fc",
    image: apr182024,
    alt: "Fc Dallas players celebrating after scoring against Houston Dynamo.",
    timeOfPost: "April 18, 24",
    title: "Classico Tejano Fc Dallas vs Houston Dynamo",
    author: "Jose Maldonado",
    keyword: "FC DALLAS",
    teamName: "Fc Dallas",
    headerSubtitle: "vs Houston Dyanmo",
    teamLogo: fcDallasLogo,
    id: 1,
  },
];
